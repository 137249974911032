<template>
  <div class="card">
    <div class="container">
      <b-modal ref="editVehiclePromotionBannersModal" hide-footer>
        <editVehiclePromotionBanners
          :editingVehiclePromotionBanners="currentVehiclePromotionBanners"
          :editMode="editMode"
          @closeEditVehiclePromotionBanners="
            toggleEditVehiclePromotionBanners()
          "
          @editVehiclePromotionBannersSuccess="
            editVehiclePromotionBannersSuccess()
          "
          @closeVehiclePromotionBannersSuccess="
            toggleAddVehiclePromotionBanners()
          "
          @addVehiclePromotionBannersSuccess="
            addVehiclePromotionBannersSuccess()
          "
        ></editVehiclePromotionBanners>
      </b-modal>
      <div class="row">
        <br>
        <h2>VehiclePromotionBanners</h2>
      </div>
      <div class="row">
        <button
          @click="toggleAddVehiclePromotionBanners()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      </div>
      <div class="row">
        <br />
        <DataBlocks
          @itemSelected="itemSelected"
          :data="items"
          :fields="['Description']"
        />
      </div>
    </div>
  </div>
</template>
<script>
import addVehiclePromotionBanners from "../../components/addVehiclePromotionBanners";
import editVehiclePromotionBanners from "../../components/editVehiclePromotionBanners";
import VueNotifications from "vue-notifications";
import { Datatable } from "../../mdb/components/Datatable";
import DataBlocks from "@/components/custom/dataBlocks.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
        {
          label: "Id",
          field: "Id",
          sort: "asc",
        },
        {
          label: "VehicleId",
          field: "VehicleId",
          sort: "asc",
        },
        {
          label: "Heading",
          field: "Heading",
          sort: "asc",
        },
        {
          label: "Copy",
          field: "Copy",
          sort: "asc",
        },
        {
          label: "Image",
          field: "Image",
          sort: "asc",
        },
        {
          label: "Link",
          field: "Link",
          sort: "asc",
        },
        {
          label: "LinkFriendly",
          field: "LinkFriendly",
          sort: "asc",
        },
        {
          label: "StartingPrice",
          field: "StartingPrice",
          sort: "asc",
        },
        {
          label: "RefLayoutId",
          field: "RefLayoutId",
          sort: "asc",
        },
        {
          label: "Commercial",
          field: "Commercial",
          sort: "asc",
        },
        {
          label: "LastUpdated",
          field: "LastUpdated",
          sort: "asc",
        },
        {
          label: "ShowText",
          field: "ShowText",
          sort: "asc",
        },
      ],
      items: [],
      addVehiclePromotionBannersOpen: false,
      editVehiclePromotionBannersOpen: false,
      currentVehiclePromotionBanners: {},
      editMode: false,
    };
  },
  components: {
    addVehiclePromotionBanners,
    editVehiclePromotionBanners,
    Datatable,
    DataBlocks,
  },
  created: function () {
    this.getVehiclePromotionBanners();
    this.addVehiclePromotionBannersOpen = false;
    this.editVehiclePromotionBannersOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    itemSelected(item) {      
      this.editMode = true;
      this.currentVehiclePromotionBanners = item;
      this.$refs.editVehiclePromotionBannersModal.show();
    },
    getVehiclePromotionBanners() {
      var payload = {
        success: (response) => {
          this.items = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getVehiclePromotionBanners", payload);
    },
    editItem(VehiclePromotionBanners) {
      this.toggleEditVehiclePromotionBanners();
      this.currentVehiclePromotionBanners = VehiclePromotionBanners;
    },
    toggleAddVehiclePromotionBanners() {
      this.editMode = false;
      if (this.addVehiclePromotionBannersOpen) {
        this.$refs.editVehiclePromotionBannersModal.hide();
      } else {
        this.$refs.editVehiclePromotionBannersModal.show();
      }
      this.addVehiclePromotionBannersOpen =
        !this.addVehiclePromotionBannersOpen;
    },
    addVehiclePromotionBannersSuccess() {
      this.toggleAddVehiclePromotionBanners();
      miniToastr["success"](
        "VehiclePromotionBanners Added",
        "Success",
        1000,
        null
      );
      this.getVehiclePromotionBanners();
    },
    toggleEditVehiclePromotionBanners() {
      this.editMode = true;
      if (this.editVehiclePromotionBannersOpen) {
        this.$refs.editVehiclePromotionBannersModal.hide();
      } else {
        this.$refs.editVehiclePromotionBannersModal.show();
      }
      this.editVehiclePromotionBannersOpen =
        !this.editVehiclePromotionBannersOpen;
    },
    editVehiclePromotionBannersSuccess() {
     this.$refs.editVehiclePromotionBannersModal.hide();
      miniToastr["success"](
        "VehiclePromotionBanners Edited",
        "Success",
        1000,
        null
      );
      this.getVehiclePromotionBanners();
    },
  },
};
</script>
<style scoped>
.VehiclePromotionBannersThumbnail {
  height: 50px;
}
</style>
