<template>
  <div class="container">
    <h2>Add A VehiclePromotionBanners</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <div class="row">
      <div class="col-md-4">
        <label class="" for="VehicleId">VehicleId</label
        ><input
          class="form-control"
          id="VehicleId"
          name="VehicleId"
          v-model="newVehiclePromotionBanners.VehicleId"
          placeholder="VehicleId"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Heading">Heading</label
        ><input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="newVehiclePromotionBanners.Heading"
          placeholder="Heading"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Copy">Copy</label
        ><input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="newVehiclePromotionBanners.Copy"
          placeholder="Copy"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class for="Image">Image</label>
        <div v-if="!editingImage">
          <img
            v-bind:src="getImageSource(newVehiclePromotionBanners)"
            class="previewImage"
          />
          <button @click="editImage()" type="button" class="btn btn-success">
            Update Image
          </button>
        </div>
        <div v-if="editingImage">
          <button @click="updateImage()" type="button" class="btn btn-success">
            Update
          </button>
          <button @click="editImage()" type="button" class="btn btn-warning">
            Cancel
          </button>
          <FileUploader
            @ImageDataChanged="updateImageData"
            @ImageURLDataChanged="updateImageUrl"
          />
        </div>
      </div>
      <div class="col-md-4">
        <label class="" for="Link">Link</label
        ><input
          class="form-control"
          id="Link"
          name="Link"
          v-model="newVehiclePromotionBanners.Link"
          placeholder="Link"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="LinkFriendly">LinkFriendly</label
        ><input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="newVehiclePromotionBanners.LinkFriendly"
          placeholder="LinkFriendly"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="StartingPrice">StartingPrice</label
        ><input
          class="form-control"
          id="StartingPrice"
          name="StartingPrice"
          v-model="newVehiclePromotionBanners.StartingPrice"
          placeholder="StartingPrice"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="RefLayoutId">RefLayoutId</label
        ><input
          class="form-control"
          id="RefLayoutId"
          name="RefLayoutId"
          v-model="newVehiclePromotionBanners.RefLayoutId"
          placeholder="RefLayoutId"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Commercial">Commercial</label>
        <br />
        <b-form-checkbox
          v-model="newVehiclePromotionBanners.Commercial"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="LastUpdated">LastUpdated</label
        ><input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="newVehiclePromotionBanners.LastUpdated"
          placeholder="LastUpdated"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="ShowText">ShowText</label>
        <br />
        <b-form-checkbox
          v-model="newVehiclePromotionBanners.ShowText"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <br />
    </div>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  name: "addVehiclePromotionBanners",
  created: function () {},
  data() {
    return {
      newVehiclePromotionBanners: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    FileUploader,
  },
  methods: {
    toggleView: function () {
      this.$emit("closeAddVehiclePromotionBanners");
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    save: function () {
      var payload = {
        data: this.newVehiclePromotionBanners,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("addVehiclePromotionBannersSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("addVehiclePromotionBanners", payload);
    },
  },
};
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
