<template>
  <div class="container">
    <h2>{{ editMode ? "Edit" : "Add" }} VehiclePromotionBanners</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button
      @click="deleteVehiclePromotionBanners()"
      type="button"
      class="btn btn-danger"
    >
      Delete
    </button>

    <div class="row">
      <div class="col-md-4">
        <label class for="Description">Description</label>
        <input
          class="form-control"
          id="Description"
          name="Description"
          v-model="editingVehiclePromotionBanners.Description"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Heading">Heading</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingVehiclePromotionBanners.Heading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-4">
        <label class for="Copy">Copy</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingVehiclePromotionBanners.Copy"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-md-3">
        <label class for="VehicleId">Vehicle</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingVehiclePromotionBanners.VehicleId"
        >
          <option
            v-for="option in vehicles"
            :key="option.Id"
            :value="option.Id"
          >
            {{ option.Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <label class for="Heading">Heading Fontsize</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingVehiclePromotionBanners.HeadingFontSize"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>

      <div class="col-md-3">
        <label class for="Copy">Heading Mobile Fontsize</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingVehiclePromotionBanners.HeadingFontSizeMobile"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>

      <div class="col-md-3">
        <label class for="Copy">Copy Fontsize</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingVehiclePromotionBanners.BodyFontSize"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <label class for="Copy">Heading Colour Mobile</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingVehiclePromotionBanners.HeadingColourMobile"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
          <option value="#002c5f">Navy</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class for="Copy">Heading Colour</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingVehiclePromotionBanners.HeadingColour"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
          <option value="#002c5f">Navy</option>
        </select>
      </div>

      <div class="col-md-3">
        <label class for="Copy">Copy Colour</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingVehiclePromotionBanners.BodyColour"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
          <option value="#002c5f">Navy</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ImageUploader
          label="Banner Image"
          v-model="editingVehiclePromotionBanners.Image"
        />
      </div>
      <div class="col-md-6">
        <ImageUploader
          label="Mobile Image"
          v-model="editingVehiclePromotionBanners.MobileImage"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ImageUploader
          label="Warranty Image One"
          :dark="true"
          v-model="editingVehiclePromotionBanners.WarrantyImageOne"
        />
      </div>
      <div class="col-md-6">
        <ImageUploader
          label="Warranty Image Two"
          :dark="true"
          v-model="editingVehiclePromotionBanners.WarrantyImageTwo"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label class for="Link">Blue Button Action Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingVehiclePromotionBanners.Link"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <label class for="Link">White Button Action Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingVehiclePromotionBanners.Link2"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="LinkFriendly">Blue Button Description</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="editingVehiclePromotionBanners.LinkFriendly"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <label class for="LinkFriendly">White Button Description</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="editingVehiclePromotionBanners.LinkFriendly2"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-4">
        <label class for="RefLayoutId">RefLayoutId</label>
        <input
          class="form-control"
          id="RefLayoutId"
          name="RefLayoutId"
          v-model="editingVehiclePromotionBanners.RefLayoutId"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label class for="StartingPrice">Starting Price SA</label>
        <input
          class="form-control"
          id="StartingPrice"
          name="StartingPrice"
          v-model="editingVehiclePromotionBanners.StartingPrice"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <label class for="StartingPrice">Starting Price Heading SA</label>
        <input
          class="form-control"
          id="StartingPrice"
          name="StartingPrice"
          v-model="editingVehiclePromotionBanners.StartingPriceHeading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-3">
        <label class for="StartingPriceNamibia">StartingPriceNamibia</label>
        <input
          class="form-control"
          id="StartingPriceNamibia"
          name="StartingPriceNamibia"
          v-model="editingVehiclePromotionBanners.StartingPriceNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />

        <label class for="StartingPriceHeadingNamibia"
          >Starting Price Heading Namibia</label
        >
        <input
          class="form-control"
          id="StartingPriceHeadingNamibia"
          name="StartingPriceHeadingNamibia"
          v-model="editingVehiclePromotionBanners.StartingPriceHeadingNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-3">
        <label class for="StartingPriceBotswana">Starting Price Botswana</label>
        <input
          class="form-control"
          id="StartingPriceBotswana"
          name="StartingPriceBotswana"
          v-model="editingVehiclePromotionBanners.StartingPriceBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />

        <label class for="StartingPriceHeadingNamibia"
          >S-Price Heading Botswana</label
        >
        <input
          class="form-control"
          id="StartingPriceHeadingBotswana"
          name="StartingPriceHeadingBotswana"
          v-model="editingVehiclePromotionBanners.StartingPriceHeadingBotswana"
          placeholder="Botswana "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-3">
        <label class for="StartingPriceSwaziland">Starting Price Swaziland</label>
        <input
          class="form-control"
          id="StartingPriceSwaziland"
          name="StartingPriceSwaziland"
          v-model="editingVehiclePromotionBanners.StartingPriceSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />

        <label class for="StartingPriceHeadingSwaziland"
          >S-Price Heading Swaziland</label
        >
        <input
          class="form-control"
          id="StartingPriceHeadingSwaziland"
          name="StartingPriceHeadingSwaziland"
          v-model="editingVehiclePromotionBanners.StartingPriceHeadingSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class="" for="Commercial">Commercial</label>
        <br />
        <b-form-checkbox
          v-model="editingVehiclePromotionBanners.Commercial"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingVehiclePromotionBanners.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class="" for="ShowText">Responsive Banner</label>
        <br />
        <b-form-checkbox
          v-model="editingVehiclePromotionBanners.ShowText"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
    </div>
    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditVehiclePromotionBanners",

  data() {
    return {
      originalData: {},
      modifiedData: {},
      vehicles: [],
    };
  },
  components: {
    ImageUploader,
  },
  created() {
    this.originalData = JSON.parse(
      JSON.stringify(this.editingVehiclePromotionBanners)
    );
    this.ImageBytes = this.editingVehiclePromotionBanners.Image;
    this.getVehicleModels();
  },

  props: ["editingVehiclePromotionBanners", "editMode"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit("closeEditVehiclePromotionBanners");
    },
    imageUploaded: function (file) {
      this.editingVehiclePromotionBanners.Image = file;
    },
    imageUploadedMobile: function (file) {
      this.editingVehiclePromotionBanners.MobileImage = file;
    },

    imageUploadedWarrantyImageOne: function (file) {
      this.editingVehiclePromotionBanners.WarrantyImageOne = file;
    },
    imageUploadedMobileWarrantyImageTwo: function (file) {
      this.editingVehiclePromotionBanners.WarrantyImageTwo = file;
    },

    getVehicleModels() {
      var payload = {
        success: (response) => {
          this.vehicles = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getVehicleModels", payload);
    },
    save: function () {
      var data = JSON.parse(
        JSON.stringify(this.editingVehiclePromotionBanners)
      );
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          if (this.editMode) {
            this.$emit("editVehiclePromotionBannersSuccess");
          } else {
            this.$emit("addVehiclePromotionBannersSuccess");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };

      if (this.editMode) {
        this.$store.dispatch("editVehiclePromotionBanners", payload);
      } else {
        this.$store.dispatch("addVehiclePromotionBanners", payload);
      }
    },

    deleteVehiclePromotionBanners: function () {
      var payload = {
        data: this.editingVehiclePromotionBanners,
        success: (response) => {
          this.$emit("editVehiclePromotionBannersSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteVehiclePromotionBanners", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
